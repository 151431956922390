<template>
  <nav
    class="navbar is-fixed-top has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item"
        href="/"
      >
        <span class="logo">Initials</span>
      </a>

      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div
      id="navbarBasicExample"
      class="navbar-menu"
    >
      <div class="navbar-end">
        <a
          class="navbar-item"
          href="/documents"
        >
          Documents
        </a>

        <div class="navbar-item">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              {{ userFullName }}
            </a>

            <div class="navbar-dropdown">
              <a
                class="navbar-item"
                href="/preferences"
              >
                Settings
              </a>
              <hr class="navbar-divider">
              <a
                class="navbar-item"
                @click="logoutApp"
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import UserInfoMixin from "@/views/auth/UserInfoMixin";

export default {
  components: {},
  mixins: [UserInfoMixin],
  data() {
    return {
      expandedMode: true,
      profileItems: ["preference", "logout"]
    };
  },
  computed: {
    userEmail() {
      if (this.userInfo) {
        return this.userInfo.email;
      }
      return "";
    },
    userFullName() {
      if (this.$store.state.user != null) {
        return (
          this.$store.state.user.first_name +
          " " +
          this.$store.state.user.last_name
        );
      }
      return "";
    }
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    logoutApp() {
      window.$cookies.set("token", "");
      window.location = "/";
      window.location.reload();
    }
  }
};
</script>

<style scoped>


.theme--light.v-app-bar.v-toolbar.v-sheet {
  border-bottom: 1px solid #e1e1e1 !important;
}
.navbar-brand,
.navbar-tabs {
  min-height: 2.85rem !important;
}
.navbar {
  min-height: 2.85rem !important;
}

</style>

