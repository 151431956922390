<template>
  <div>
    <app-bar />
    <div class="content-view">
      <router-view :key="$route.params.id " />
    </div>
  </div>
</template>

<script>
import AppBar from "./AppBar";

export default {
  name: "dashboard",
  components: {
    AppBar
  },
  data: () => ({})
};
</script>
<style scoped>
.content-view {
  margin-top: 80px;;
}
</style>