var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar is-fixed-top has-shadow",
      attrs: { role: "navigation", "aria-label": "main navigation" }
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "navbar-menu", attrs: { id: "navbarBasicExample" } },
        [
          _c("div", { staticClass: "navbar-end" }, [
            _c(
              "a",
              { staticClass: "navbar-item", attrs: { href: "/documents" } },
              [_vm._v(" Documents ")]
            ),
            _c("div", { staticClass: "navbar-item" }, [
              _c(
                "div",
                { staticClass: "navbar-item has-dropdown is-hoverable" },
                [
                  _c("a", { staticClass: "navbar-link" }, [
                    _vm._v(" " + _vm._s(_vm.userFullName) + " ")
                  ]),
                  _c("div", { staticClass: "navbar-dropdown" }, [
                    _c(
                      "a",
                      {
                        staticClass: "navbar-item",
                        attrs: { href: "/preferences" }
                      },
                      [_vm._v(" Settings ")]
                    ),
                    _c("hr", { staticClass: "navbar-divider" }),
                    _c(
                      "a",
                      {
                        staticClass: "navbar-item",
                        on: { click: _vm.logoutApp }
                      },
                      [_vm._v(" Logout ")]
                    )
                  ])
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-brand" }, [
      _c("a", { staticClass: "navbar-item", attrs: { href: "/" } }, [
        _c("span", { staticClass: "logo" }, [_vm._v("Initials")])
      ]),
      _c(
        "a",
        {
          staticClass: "navbar-burger burger",
          attrs: {
            role: "button",
            "aria-label": "menu",
            "aria-expanded": "false",
            "data-target": "navbarBasicExample"
          }
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _c("span", { attrs: { "aria-hidden": "true" } })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }