var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-bar"),
      _c(
        "div",
        { staticClass: "content-view" },
        [_c("router-view", { key: _vm.$route.params.id })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }